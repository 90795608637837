<template>
    <div style="width: 100%;height: 100%;overflow: hidden;"> 
        <!-- <nav-bar></nav-bar> -->
        <iframe :src="'https://www.3d.sumgkee.com/?name='+query.name" style="width: 100%;height:100%;margin:0px 0 0;border: 0;"></iframe>

    </div>
   
        
</template>
<script>
import navBar from '@/components/NavBar'
import { useRoute, useRouter } from 'vue-router'
export default {
    components: {
        navBar,
  
    },
    setup(){
        const route = useRoute();
        const router = useRouter();
        const { params, query } = route;
        return{
            query
        }
   
    }
}
</script>
<style>
html,body,#app{
    height: 100%;
}
</style>